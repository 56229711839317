
















































import agent from "@/helpers/agent";
import Loader from "@/helpers/loader";
import Notifier from "@/helpers/notifier";
import { AppUser, ResetPasswordVM } from "@/models/user";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
const app = namespace("app");

@Component
export default class Password extends Vue {
  @app.Getter
  user!: AppUser;

  @app.Action
  updateUser: any;

  newPassword = "";

  password = "";

  isPwd = true;
  isPwdConfirm = true;

  public onSubmit() {
    const vm: ResetPasswordVM = {
      newPassword: this.newPassword,
      user: this.user,
      password: this.password,
    };
    agent.Users.changePassword(this.user.id, vm)
      .then(() => {
        this.newPassword = "";
        this.password = "";
        Notifier.showPositive(`You have succesfully changed your password`);
      })
      .catch(() => [Notifier.showError("There was an error updating profile")])
      .finally(() => Loader.hideLoader());
  }
}
