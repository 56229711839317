





























































import { ApiEndPoints } from "@/helpers/api_endpoints";
import Loader from "@/helpers/loader";
import { updateToApi } from "@/helpers/network_util";
import Notifier from "@/helpers/notifier";
import { AppUser } from "@/models/user";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
const namespace = "app";

@Component
export default class Email extends Vue {
  @Action("updateUser", { namespace })
  updateUser: any;

  @Getter("user", { namespace })
  user!: AppUser;

  private newEmail = "";

  isValidEmail(val: string) {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return emailPattern.test(val) || "Invalid email";
  }

  public onSubmit() {
    updateToApi<AppUser>(ApiEndPoints.users, this.user.id, this.user)
      .then((user) => {
        Loader.hideLoader();
        Notifier.showPositive(`You have succesfully updated your profile`);
        this.updateUser(user);
      })
      .catch(() => [
        Loader.hideLoader(),
        Notifier.showError("There was an error updating profile"),
      ]);
  }
}
