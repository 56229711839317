




































































































import { ApiEndPoints } from "@/helpers/api_endpoints";
import { updateToApi } from "@/helpers/network_util";
import Notifier from "@/helpers/notifier";
import Loader from "@/helpers/loader";
import { AppUser } from "@/models/user";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import agent from "@/helpers/agent";
import FinancialInstitution from "@/models/financialInstitution";

const app = namespace("app");

@Component
export default class General extends Vue {
  @app.Action updateUser: any;
  @app.Getter user!: AppUser;

  institutions: FinancialInstitution[] = [];

  beforeMount() {
    agent.FinancialInstitutions.list("", true, 1, -1, "Name").then(
      (list) => (this.institutions = list.rows)
    );
  }
  get readonly() {
    return this.user.role !== "SuperAdmin";
  }
  public onSubmit() {
    updateToApi<AppUser>(ApiEndPoints.users, this.user.id, this.user)
      .then((user) => {
        Loader.hideLoader();
        Notifier.showPositive(`You have succesfully updated your profile`);
        this.updateUser(user);
      })
      .catch(() => [
        Loader.hideLoader(),
        Notifier.showError("There was an error updating profile"),
      ]);
  }
}
