































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import General from "@/components/profile/General.vue";
import Email from "@/components/profile/Email.vue";
import Password from "@/components/profile/Password.vue";

enum profileTabs {
  general,
  email,
  password,
}

@Component({
  components: { General, Email, Password },
})
export default class Profile extends Vue {
  profileData = {
    firstname: "",
    surname: "",
    cardNumber: "",
    role: "",
    email: "",
    password: "",
  };
  tab = profileTabs.general;
  get showGeneral(): boolean {
    return this.tab === profileTabs.general;
  }
  setGeneral(): void {
    this.tab = profileTabs.general;
  }
  get showEmail(): boolean {
    return this.tab === profileTabs.email;
  }
  setEmail(): void {
    this.tab = profileTabs.email;
  }
  get showPassword(): boolean {
    return this.tab === profileTabs.password;
  }
  setPassword(): void {
    this.tab = profileTabs.password;
  }
  beforeMount() {
    this.setGeneral();
  }
}
